import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

import { getValidationDetails } from "../notices/validation_details";
import { mappingWarnings } from "../notices/mapping_warning";

const Errors = (props) => {
  const renderMissingFieldsError = () => {
      var error = getValidationDetails("missing_fields");

      return (
        <div className="notice__banner notice__banner--warning notice__banner--m-btm animated fadeInUp">
          {error.title}
          <p>{error.description}</p>
        </div>
      );
  }
  const renderInvalidDateError = () => {
      var error = getValidationDetails("invalid_date");

      return (
        <div className="notice__banner notice__banner--warning notice__banner--m-btm animated fadeInUp">
          {error.title}
          <p>{error.description}</p>
        </div>
      );
  }
  const renderMappingMismatchError = () => {
      var error = getValidationDetails("mapping_mismatch");

      return (
        <div className="notice__banner notice__banner--warning notice__banner--m-btm animated fadeInUp">
          {error.title}
          <p>{error.description}</p>
        </div>
      );
  }

  const renderErrors = () => {
    return (
      <>
        {props.mappingMismatch && renderMappingMismatchError()}
        {props.hasError && renderMissingFieldsError()}
        {!!_.intersection(props.columnsWithErrors, ["date_prescribed", "invoice_date", "date_of_service"]).length && renderInvalidDateError()}
      </>
    );
  };

  return <div className="notify__error__container">{renderErrors()}</div>;
};

export default Errors;
